import { graphql } from 'gatsby';
import React from 'react';
import LandingHeader from '../components/global/landing-header/landing-header';
import Layout from '../components/global/layout/layout';
import MailchimpSignup from '../components/global/mailchimp-signup/mailchimp-signup';
import Intro from '../components/home/intro/intro';

export default function ({ data: { allPrismicBlogPost: posts, image_vorher_calvin, image_nachher_calvin, image_ueber_mindfoe } }) {

  return (
    <Layout footerPosts={posts} title="About" description="Ich heiße Calvin &amp; bin zweiundzwanzig Jahre alt. Wenn ich nicht gerade an dieser Website bastle, am Laufen oder am Schreiben bin, studiere ich Sozialpädagogik und Management in Kassel.">
      <LandingHeader
        text="About Me, about Mindfoe."
        fullHeight={true}
        backgroundImage="'/images/mindfoe.svg'"
      ></LandingHeader>
      <Intro
        heading="Über Calvin"
        images={[
          { fluid: image_vorher_calvin.childImageSharp.fluid }
        ]}
        smallerImage={true}
        hideReadMoreLink={true}
        hideSocialIcons={true}
        paragraphs={[
          'Ich heiße <strong>Calvin</strong> & bin <strong>zweiundzwanzig</strong> Jahre alt. Wenn ich nicht gerade an dieser Website bastle, am Laufen oder am Schreiben bin, studiere ich Sozialpädagogik und Management in Kassel.',
          'Auf dieser Seite möchte ich euch einen kleinen Einblick darin geben, mit wem ihr es hier überhaupt zu tun habt. Besonders von Wert is es wahrscheinlich nicht, aber ich möchte, dass man verstehen kann, woher das alles hier kommt.',
          'Vor einer Weile habe ich gemerkt, dass ich verdammt <strong>unglücklich</strong> bin. Mit so ziemlich allem, irgendwie. Ich verbrachte jeden Tag nur vor dem Computer und lies die Zeit beim <strong>Zocken</strong> oder beim gedankenlosen <strong>Serien-Bingewatching</strong> nur so dahin treiben. Ich hatte wenig Trieb etwas mit meinem Leben anzufangen. Ich war einfach zu gemütlich um irgendetwas produktives zu machen.'
        ]}>
      </Intro>
      <Intro
        images={[
          { fluid: image_nachher_calvin.childImageSharp.fluid }
        ]}
        smallerImage={true}
        hideReadMoreLink={true}
        hideSocialIcons={true}
        mirror={true}
        paragraphs={[
          'Ich bin stolz behaupten zu können, dass sich dies nun geändert hat. Ich bin noch lange nicht fertig, aber ich habe mir <strong>Ziele</strong> gesetzt und den <strong>Ehrgeiz<strong> gefunden, aktiv daran zu arbeiten',
          'Eine meiner vielen Laster sind und waren mein Gewicht. Ich bin immernoch stark übergewichtig, obwohl ich schon 20 Kilo runter habe. Zu diesem Zeitpunkt sind es 101,20 Kilogramm. Wenn ihr in Mathe aufgepasst habt stellt ihr fest, dass es einmal 121,20 waren. <strong>Einhunderteinundzwanzig</strong>.',
          'Den ersten Schritt in richtige Richtung habe ich gemacht, nachdem ich Ende 2018 das Laufen für mich entdeckt habe. Meine Freundin hatte stark mit mir zu kämpfen, um mich dazu zu motivieren mich mit ihr für den jährlichen Winterlauf unseres netten 1100-Bewohner Dörfchens anzumelden. Der Anfang war verdammt hart, aber ich bin ihr unendlich dankbar, dass sie mich <strong>nicht aufgegeben</strong> hat.',
          'Nun, nur ein paar Monate später, <strong>kann ich nicht mehr ohne Laufen</strong>. Ich drehe regelmäßig meine Runden – meist täglich. Wenn ich morgens laufen gehe habe ich Schwierigkeiten damit mich zusammenzureißen, nicht Abends nochmal zu gehen. Man soll’s ja nicht übertreiben.',
          'Es hat sich vieles geändert – und es tut verdammt gut.',
          'Weil ich weiß wie es sich anfühlt einfach nur vor sich hin zu leben und nie wirklich Glücklich zu sein habe ich diese Website erstellt.'
        ]}>
      </Intro>
      <MailchimpSignup
        alwaysVertical={false}
        outerClass="py-5"
        slice={{
          primary: {
            title1: {
              text: '5 super Tipps, um mit dem Abnehmen zu beginnen!'
            },
            text: {
              html: '<p>Zwei Seiten, gefüllt mit 5 nützlichen Tipps & Tricks, damit Du mit dem Abnehmen beginnen kannst! Trage dich jetzt ein und erhalte die PDF in wenigen Minuten. Auch super zum Ausdrucken geeignet!</p>'
            }
          }
        }}
      />
      <Intro
        heading="Über Mindfoe"
        images={[
          { fluid: image_ueber_mindfoe.childImageSharp.fluid }
        ]}
        hideReadMoreLink={true}
        hideSocialIcons={true}
        paragraphs={[
          'Hier möchte ich einen kleinen Einblick darüber geben, wieso es diese Website gibt',
          'Zuerst: <strong>Mindfoe? Was soll das bedeuten?</strong>',
          'Ich habe sehr lang über einen passenden Namen für meine Seite nachgedacht. Ich habe lang darüber gegrübelt, was wohl am passenden ist. Irgendwas zum Laufen? Abnehmen? <strong>Nein!</strong>.',
          'Ich bin davon überzeugt, dass der Kopf das Wichtigste ist. Egal, was du erreichen willst. Es scheitert eigentlich immer nur an deinem Kopf und eben daran, was darin so vorgeht. Daher auch der Name.',
          '<strong>Mind</strong> ist Englisch und bedeutet <strong>Verstand</strong>. Einfach das, was in deinem Kopf so abgeht. Das ist also der innere Schweinehund, den es zu überwinden gilt.',
          'Daher auch foe. <strong>Foe</strong> ist ebenfalls Englisch und bedeutet <strong>Gegner</strong>. Mindfoe bedeutet also nicht mehr als dass dein Verstand dein Gegner ist. So ist es zumindest gemeint.',
          'Davon bin ich fest entschlossen. Es dreht sich eigentlich alles nur darum. Ob du abnehmen, zunehmen oder deinen ersten – oder zwanzigsten – Marathon laufen willst. <strong>Wenn dein Kopf nicht mitmacht kann dein Körper wenig reißen.</strong>',
          'Das habe ich auch selbst gemerkt. Ich habe das Laufen lieben gelernt – weil es mich fordert. Natürlich, körperlich ist es verdammt anstrengend, gerade am Anfang. Aber mein Gott ist es ein Gefühl, wenn du nach einem langen Lauf nach Hause kommst und du weißt, wie sehr du dich überwinden musstest um es durchzuziehen.',
          'Ich habe diesen Schmerz lieben gelernt. Er gibt mir einfach das Gefühl lebendig zu sein, verstehst du?'
        ]}>
      </Intro>
    </Layout>
  );
}

export const pageQuery = graphql`
  {
    image_vorher_calvin: file(name: {eq: "vorher-calvin"}) {
      childImageSharp {
        fluid(maxWidth: 2400) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    image_nachher_calvin: file(name: {eq: "calvin-nachher-sw"}) {
      childImageSharp {
        fluid(maxWidth: 2400) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    image_ueber_mindfoe: file(name: {eq: "ueber-mindfoe"}) {
      childImageSharp {
        fluid(maxWidth: 2400) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    allPrismicBlogPost(limit: 3, sort: {fields: first_publication_date, order: DESC}) {
      nodes {
        uid
        data {
          title {
            text
          }
          excerpt {
            text
          }
          title_image {
            url
          }
        }
        first_publication_date_from_now: first_publication_date(fromNow: true)
        first_publication_date_time: first_publication_date(formatString: "hh:mm")
        first_publication_date: first_publication_date(formatString: "DD.MM.YYYY")
        first_publication_date_day: first_publication_date(formatString: "dddd", locale: "de-DE")
        tags
      }
    }
  }
`
