import { Link } from 'gatsby';
import Img from 'gatsby-image';
import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useInterval } from '../../../utils/hooks';
import { FacebookIcon, InstagramIcon, YoutubeIcon } from '../../global/icons/icons';
import Styles from './intro.module.scss';

export default ({ heading, paragraphs, images, smallerImage, hideReadMoreLink, mirror, hideSocialIcons }) => {
    const [image, setImage] = useState(images[0]);

    useInterval(() => {
        const currIndex = images.indexOf(image);
        let newIndex = currIndex + 1;
        if (currIndex === (images.length - 1)) {
            newIndex = 0;
        }
        setImage(images[newIndex]);
    }, 4000);

    return (
        <Container className="pt-5 pb-md-4 my-5">
            <Row>
                <Col xs="12" md={{span: smallerImage ? '4' : '6', offset: mirror ? 1 : 0}} className={`position-relative mb-5 mb-lg-0 ${mirror ? 'order-lg-2' : ''}`}>
                    <div className={`${Styles.bar} ${mirror ? Styles.barMirrored : ''}`}></div>
                    <Img className="w-100" fluid={image.fluid}/>
                </Col>
                <Col xs="12" md={{ span: smallerImage ? 7 : 5, offset: mirror ? 0 : 1 }}>
                    {heading ? (<h2 className="text-pink font-weight-bold">{heading}</h2>) : ''}
                    {paragraphs.map((text, index) => <p key={index} dangerouslySetInnerHTML={{ __html: text }}></p>)}
                    {hideReadMoreLink ? '' : (<Link to="/about">Mehr erfahren</Link>)}
                </Col>
            </Row>
            {hideSocialIcons ? '' : (
                <Row className="mt-4">
                    <Col className={Styles.socialIconContainer}>
                        <FacebookIcon/>
                        <InstagramIcon/>
                        <YoutubeIcon/>
                    </Col>
                </Row>
            )}
        </Container>
    );
}
